<template>
  <div class="container">

    <div v-if="!hidden" class="content">
      <iframe
          id="iframe"
          allow="camera *; microphone *"
          src="https://demo.readyplayer.me/"
          :hidden="hidden"
      ></iframe>
    </div>


    <button v-if="myImg" @click="filterFunc"> Filter </button>

    <p>{{myImg}}</p>
    <div>
      <svg width="427" height="427" viewBox="0 0 427 427">
        <filter id="stroke">
          <feMorphology operator="dilate" radius=".2"
                        in="SourceGraphic" result="outside" />
          <feMorphology operator="dilate" radius="1.1"
                        in="outside" result="thickened" />
        </filter>
        <image :xlink:href="myImg" width="100%" height="100%" x="0" y="0" filter="url(#stroke)"></image>
      </svg>
    </div>


    <div v-if="imgS && hidden" class="img-container">
      <img v-for="(avatarUrl,idx) in imgS" :key="idx" :src="avatarUrl" alt="avatar">
    </div>

    <div v-if="imgS && hidden" class="button-holder">
      <button v-if="imgS.length!==0" @click.prevent="changeAvatar">change avatar</button>
    </div>

    <p>{{ error }}</p>


  </div>
</template>

<script>


import axios from "axios";


export default {
  name: "avatarPlayerMe",
  data() {
    return {
      url: '',
      hidden: true,
      imgS: [],
      error: null,
      myImg:''
    }
  },

  mounted() {
    // Listen to messages from the iframe
    window.addEventListener('message', this.receiveMessage, false);

    localStorage.getItem('avatars') ? this.imgS.push(localStorage.getItem('avatars')) : []
    this.myImg=localStorage.getItem('avatars')

    if (!this.imgS || this.imgS.length === 0) {
      this.displayIframe()
    }

  },
  methods: {
    displayIframe() {
      this.hidden = false
    },
    async receiveMessage(e) {
      if (typeof e.data === 'string' && e.data.startsWith('https://') &&
          e.data.endsWith('.glb')) {
        this.url = e.data
        await this.createAvatar()

      } else {
        console.log('url from unknown source')
      }
    },
    async createAvatar() {

      const payload = {
        model: this.url,
        scene: "fullbody-portrait-v1",
        armature: "ArmatureTargetMale",
        blendShapes: {}
      }
      this.hidden = true

      try {
        const response = await axios.post("https://render.readyplayer.me/render", JSON.stringify(payload))
        const responseImage = response.data.renders
        localStorage.setItem('avatars', responseImage)
        this.imgS=[...responseImage]
        this.myImg = this.imgS[0]
      } catch (e) {
        this.error = e
      }
    },
    changeAvatar() {
      localStorage.removeItem('avatars')
      this.imgS = []
      this.displayIframe()
    }

  }
}
</script>

<style scoped lang="scss">


.container {
  position: relative;
}

.content {

  border: none;
  width: 100%;
  height: auto;

  iframe {
    width: 1280px;
    height: 800px;
    margin: 0 auto;
    display: block;
  }
}

.img-container {
  display: flex;
  justify-content: center;
  margin-top: 10rem;

  img {
    height: 60vh;
    width: auto;
    object-fit: contain;
    margin: 0 auto;
  }
}

.button-holder {
  width: 100%;

  button {
    margin: 2rem auto;
    display: block;
    border-radius: 2rem;
    padding: 1rem 2rem;
    background-color: #3ab667;
    color: white;
  }

}


</style>
